// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { DialogStatus, useDialog } from "./use-dialog";
import {
	Button,
	Checkbox,
	CheckboxProps,
	Heading,
	Paragraph,
	Textarea,
	TextareaProps,
} from "@cue/atoms";
import { useForm } from "@cue/hooks";
import { useCategories } from "@project/hooks/use-categories";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export interface ContactForm {
	topic?: { value: string; label: string }[] | null;
/* 	kind_of_contact?: { value: string; label: string } | null;
 */	more_informations?: boolean;
	project_request?: boolean;
	message?: string;
}
const schema = yup.object().shape({
	topic: yup
		.array()
		.of(
			yup.object().shape({
				value: yup.string().required(),
				label: yup.string().required(),
			}),
		)
		.min(1)
		.required(),
/* 	kind_of_contact: yup
		.object()
		.shape({
			value: yup.string().required(),
			label: yup.string().required(),
		})
		.required(), */
	more_informations: yup.boolean().required(),
	project_request: yup.boolean().required(),
	message: yup.string(),
});

export const DialogContact = () => {
	const { t, i18n:{language} } = useTranslation();
	const { status, sendData } = useDialog();
	const { categories } = useCategories();

	const {
		form: { formState, handleSubmit },
		registerElement,
		registerSelect,
	} = useForm<ContactForm>({
		schema,
		defaultValues: {
			topic: null,
			more_informations: false,
			project_request: false,
			message: "",
		},
	});
	const onSubmit = (values: ContactForm) => {
		sendData(values);
	};

	//return <pre>{JSON.stringify(categories, null, 4)}</pre>

	const languageTopics = {"de": "topics", "en": "topics-en", "es":"temas"};
	const topics = languageTopics?.[language] || "topics-en";

	return (
		<div className="cue-contact-form">
			{[DialogStatus.DEFAULT, DialogStatus.SENDING].includes(status) && (
				<>
					<div className="cue-contact-form-fields">
						<Heading type="h5">{t("contact.headline")}</Heading>
						<Paragraph className="cue-contact-form-label">
							{t("contact.more-information")}
						</Paragraph>
						<div className="cue-contact-form-field">
							{registerSelect({
								name: "topic",
								props: {
									error:
										formState.isSubmitted &&
										formState.errors.topic !== undefined,
									label: `* ${t("contact.topic")}`,
									isMulti: true,
									options: categories
										?.find((category) => category.slug === topics)
										?.children?.filter(({ slug }) => slug !== "topics-partner")
										.map(({ slug, title }) => ({ value: slug, label: title })),
								},
							})}
						</div>
				{/* 		<div className="cue-contact-form-field">
							{registerSelect({
								name: "kind_of_contact",
								props: {
									error:
										formState.isSubmitted &&
										formState.errors.kind_of_contact !== undefined,
									label: `* ${t("contact.kind_of_contact.label")}`,
									options: [
										{
											value: "online",
											label: t("contact.kind_of_contact.online"),
										},
										{
											value: "onsite",
											label: t("contact.kind_of_contact.onsite"),
										},
									],
								},
							})}
						</div> */}
						<Paragraph className="cue-contact-form-label">
							{t("contact.kind-of-request")}
						</Paragraph>{" "}
						<div className="cue-contact-form-field-column"><div className="cue-contact-form-field cue-contact-form-field-no-margin">
							{registerElement<CheckboxProps>({
								element: Checkbox,
								name: "more_informations",
								props: {
									label: t("contact.more-informations"),
									error:
										formState.isSubmitted &&
										formState.errors.more_informations !== undefined,
								},
							})}
						</div>
						<div className="cue-contact-form-field">
							{registerElement<CheckboxProps>({
								element: Checkbox,
								name: "project_request",
								props: {
									label: t("contact.project-request"),
									error:
										formState.isSubmitted &&
										formState.errors.project_request !== undefined,
								},
							})}
						</div>{" "}</div>
						<div className="cue-contact-form-field">
							{registerElement<TextareaProps>({
								element: Textarea,
								name: "message",
								props: {
									label: t("contact.message"),
									error:
										formState.isSubmitted &&
										formState.errors.message !== undefined,
								},
							})}
						</div>
					</div>
					<Button
						type="button"
						disabled={
							/* !formState.isValid ||  */ status === DialogStatus.SENDING
						}
						className="cue-contact-form-submit"
						onClick={handleSubmit(onSubmit)}
					>
						{t("contact.send")}
					</Button>
				</>
			)}

			{status === DialogStatus.SENT && (
				<>
					<div className="cue-contact-form-fields">
						<Heading type="h2" style={{ marginTop: "50px" }}>
							{t("contact.sent.header")}
						</Heading>
						<Paragraph>{t("contact.sent.description")}</Paragraph>
					</div>
				</>
			)}
		</div>
	);
};
