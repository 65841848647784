import { ChatInput } from './chatInput';
import { ChatMessages } from './chatMessages';
import { Button, LoadingSpinner } from '@cue/atoms';
import { StyledComponent } from '@cue/theme';
import { CSSObject } from '@emotion/styled';
import { ChatClientConfig, useChatReturn } from '@project/hooks/use-chat';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

export const Chat: React.FC<ChatProps & StyledComponent<ChatStyleOverride>> = ({
  chat,
  user,
  username = '',
  showMessageInput = true,
  layout = 'default',
  isGuest,
}) => {
  const [scrollKey, setScrollKey] = useState<HTMLElement>();
  const chatMessagesContainer = useRef<HTMLDivElement>(null);

  // const internal_chat = useChat({
  //   room,
  //   url,
  //   autoConnect: false,
  // });
  const { connect, send, sendAsGuest, history, pendingMessages, connected } = chat;

  const memoHistory = useMemo(() => history, [history]);
  useEffect(() => {
    if (user) {
      connect({ user, token: user.chatToken });
    }
  }, [user, connect]);

  useEffect(() => {
    if (user) {
      //setUsername(`${user.firstName} ${user.lastName}`);
    }
  }, [user]);

  useEffect(() => {
    if (chatMessagesContainer?.current) {
      chatMessagesContainer.current.scrollTop = chatMessagesContainer?.current.scrollHeight;
    }
  }, [memoHistory]);

  useEffect(() => {
    setTimeout(() => {
      scrollKey?.scrollTo(0, 99999);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoHistory]);

  const { t } = useTranslation();

  const [formStatus, setFormStatus] = React.useState<'idle' | 'messageSent' | 'error'>('idle');

  if (layout === 'contactForm') {
    return (
      <div className="cue-chat-contact-form">
        <h4 className="cue-chat-contact-form__title">
          {formStatus === 'messageSent'
            ? t('livestream.question-box.headline-thank-you')
            : t('livestream.question-box.headline')}
        </h4>
        <p className="cue-chat-contact-form__description">
          {formStatus === 'messageSent'
            ? t('livestream.question-box.text-thank-you')
            : t('livestream.question-box.text')}
        </p>
        {connected || isGuest ? (
          <>
            {showMessageInput && (
              <div className="cue-chat-input-container">
                {['idle', 'error'].includes(formStatus) ? (
                  <ChatInput
                    error={
                      formStatus === 'error'
                        ? new Error(t('livestream.question-box.general-error'))
                        : undefined
                    }
                    multiline
                    onSend={async (data) => {
                      const sendResult = isGuest
                        ? await sendAsGuest(data.message)
                        : await send({ text: data.message, username });

                      if (!sendResult) {
                        setFormStatus('error');
                        return;
                      }
                      setFormStatus('messageSent');
                    }}
                    buttonProps={{
                      label: t('livestream.question-box.cta.label'),
                    }}
                    inputProps={{
                      placeholder: t('livestream.question-box.input-placeholder'),
                    }}></ChatInput>
                ) : (
                  <Button onClick={() => setFormStatus('idle')}>
                    {t('livestream.question-box.cta.another-question-label')}
                  </Button>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="cue-chat-loading">
            <LoadingSpinner />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="cue-chat">
      {connected || isGuest ? (
        <>
          <div className="cue-chat-messages-container" ref={chatMessagesContainer}>
            <PerfectScrollbar
              containerRef={(ref) => {
                setScrollKey(ref);
                // onScrollerInit && onScrollerInit(ref);
              }}>
              <ChatMessages history={history} pendingMessages={pendingMessages} user={username} />
            </PerfectScrollbar>
          </div>

          {showMessageInput && (
            <div className="cue-chat-input-container">
              <ChatInput onSend={(data) => send({ text: data.message, username })}></ChatInput>
            </div>
          )}
        </>
      ) : (
        <div className="cue-chat-loading">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export interface ChatProps {
  config?: ChatClientConfig;
  user?: ChatUser;
  username?: string;
  chat: useChatReturn;
  showMessageInput?: boolean;
  roomId?: string;
  layout?: 'default' | 'contactForm';
  isGuest?: boolean;
}

export type ChatStyleOverride = {
  '.cue-chat-messages-container'?: CSSObject;
  '.cue-chat-input-container'?: CSSObject;
  '.cue-chat-loading'?: CSSObject;
};

export interface ChatUser {
  firstName: string;
  lastName: string;
  chatToken: string;
}
