import { Chat, ChatUser } from '@project/cms/modules/chat'
import { LocalUserData } from '@project/cue-api/auth'
import { useChat, useChatReturn } from '@project/hooks/use-chat'
import { useUser } from '@project/hooks/use-user'
import { ResendAccesModal } from '@project/specific/components/resend-acess-modal'
import React, { useEffect, useState } from 'react'
import { apiConfig } from '../../../config/api-config'
import { Button } from '@cue/atoms'
type CTAChatProps = {
  onClose: () => void
}
export const CTAChat = (props:CTAChatProps) => {

  const { onClose } = props
  const {isAuthenticated, user, getChatToken} = useUser()
  const room = "si_ep_main"
  const { firstName = "", lastName = "" } = (user as LocalUserData) || {
		firstName: "",
		lastName: "",
	};
  const chatUser: ChatUser = {
		firstName,
		lastName,
		chatToken: "",
	};
	const [chatToken, setChatToken] = useState<string | null>();
  
	useEffect(() => {
	
		if (!chatToken &&  room) {
			getChatToken(room).then((token) => setChatToken(token));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [room, chatToken]);


  const chat: useChatReturn = useChat({
		url: apiConfig.chat,
	});

  const {
		connected: chatConnected,
		sendReaction,
		recentEmoji,
		connect,
		disconnect,
	} = chat;

  useEffect(() => {
		// Connect if recieved token
		if (
      chatToken && isAuthenticated && 
			!chatConnected
		) {
      console.log("CONNECT CHAT", chatToken, room, chatUser)
			connect({ user: chatUser, token: chatToken || "", room });
		} else {
			//disconnect();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chatToken, chatConnected, isAuthenticated]);


  console.log("isAuthenticated", isAuthenticated)
  return (
    <div className='cta-chat'><Button styling="iconOnly" icon="close" className="close-btn" onClick={()=>onClose()} />{isAuthenticated ? <>{chatConnected && <Chat
      roomId={room}
      chat={chat}
      username={`${chatUser.firstName} ${chatUser.lastName}`}
    />}</> : <ResendAccesModal small fromAdhoc={false} /> }</div>
  )
}
/* isAuthenticated ? <Chat
						isGuest={!isAuthenticated}
						roomId={room}
						chat={chat}
						username={`${chatUser.firstName} ${chatUser.lastName}`}
						layout={sidebarChatOneWay ? "contactForm" : undefined}
					/> : <ResendAccesModal small fromAdhoc={false} /> */