import { Badge } from '@cue/atoms';
import styled, { CSSObject } from '@emotion/styled';
import { ChatMessageSocket } from '@project/hooks/use-chat';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ChatMessage: React.FC<ChatMessageSocket & { user?: string; pending?: boolean }> = (
  rest,
) => {
  const { t } = useTranslation();
  const { user, username, text, pending, rejectedReason, answers = [] } = rest;
  const classes = ['cue-chat-message'];
  const isOwner = user === username;
  if (pending) classes.push('cue-chat-message-pending');
  if (rejectedReason) classes.push('cue-chat-message-rejected');
  /*   if (isAnswer) classes.push('cue-chat-message-answer');
   */ if (isOwner) classes.push('cue-chat-message-owner');
  if (parent) classes.push('cue-chat-message-reply');

  const parseTextWithLinks = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part, index) => {
    if (part.match(urlRegex)) {
      const maxLength = 30;
      const halfLength = Math.floor(maxLength / 2);
      const displayText =
        part.length > maxLength
          ? `${part.substring(0, halfLength)}...${part.substring(part.length - halfLength)}`
          : part;
      return (
        <a key={index} title={part} href={part} target="_blank" rel="noopener noreferrer">
          {displayText}
        </a>
      );
    }
    return part;
  });
};
  return (
    <div className={`cue-chat-message-wrapper ${isOwner ? 'cue-chat-message-wrapper-owner' : ''}`} >
      <ChatMessageContainer className={classes.join(' ')} data-username={username}>
        <div className="cue-chat-message-content">
          <div className="cue-chat-message-user">{username}</div>
          <div className="cue-chat-message-text">{parseTextWithLinks(text || "")}</div>
          {answers.length > 0 ? (
            <div className="cue-chat-message-answers">
              {answers?.map((answer) => (
                <ChatMessage user={user} key={answer.id} {...answer}></ChatMessage>
              ))}
            </div>
          ) : (
            <></>
          )}
          {(pending || rejectedReason) && (
            <div
              className={`cue-chat-message-status ${
                pending ? 'cue-chat-message-status-pending' : ''
              } ${rejectedReason ? 'cue-chat-message-status-rejected' : ''}`}>
              <Badge
                value={
                  pending
                    ? t('chat.message.is-pending')
                    : rejectedReason
                      ? t(rejectedReason)
                      : 'Rejected'
                }
                styling={rejectedReason ? 'warning' : 'pending'}
                relativePosition
              />
            </div>
          )}
        </div>
      </ChatMessageContainer>
    </div>
  );
};

export type ChatMessageStyleOverride = {
  '.cue-chat-message'?: CSSObject;
  '.cue-chat-message-text'?: CSSObject;
  '.cue-chat-message-pending'?: CSSObject;
  '.cue-chat-message-pending-text'?: CSSObject;
  '.cue-chat-message-rejected'?: CSSObject;
  '.cue-chat-message-higlight'?: CSSObject;
  '.cue-chat-message-owner'?: CSSObject;
  '.cue-chat-message-reply'?: CSSObject;
};

const ChatMessageContainer = styled.div``;
