import { apiConfig } from "../../../config/api-config";
import { ContactForm } from "./dialog-contact";
import { useToastNotification } from "@cue/organisms";
import { useTheme } from "@emotion/react";
import { HttpService } from "@project/cue-api/http";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export enum DialogStatus {
	DEFAULT = "default",
	ERROR = "error",
	SENDING = "sending",
	SENT = "sent",
}

export const useDialog = (): useDialogReturn => {
	const [status, setStatus] = useState(DialogStatus.DEFAULT);
	const { t } = useTranslation();

	const notification = useToastNotification();
	// notification.addTheme(useTheme());
	const sendData = async (value: ContactForm) => {
		setStatus(DialogStatus.SENDING);

		const tags = [
			...[value?.topic?.map(({ label }) => `Topic: ${label}`).join(", ")],
	

			/*       ...[`Kontaktwunsch: ${value?.kind_of_contact?.map(({ label }) => label).join(' / ')}`],
			 */
		];
		if (value.project_request || value.project_request) {
			const add = [];
			if (value.more_informations) add.push("Weitere Informationen");
			if (value.project_request) add.push("Projektanfrage");
			tags.push(`Art der Anfrage: ${add.join(" / ")}`);
		}
		/* 	if (value.message) {
			tags.push(`Sonstiges: ${value.message}`);
		} */
		const data = {
			title: "Kontaktanfrage",
			tags,
			description: value.message,
		};

		await HttpService.post({
			url: `${apiConfig.appointments}`,
			data,
			config: { withCredentials: true },
		}).catch(() => {
			notification.error({
				title: t("contact.error.title"),
				message: t("contact.error.message"),
			});
			setStatus(DialogStatus.DEFAULT);
		});
		setStatus(DialogStatus.SENT);

		setTimeout(() => {
			//setStatus(DialogStatus.SENT);
		}, 1000);
	};

	const resetStatus = () => {
		setStatus(DialogStatus.DEFAULT);
	};
	return { status, resetStatus, sendData };
};
export interface useDialogReturn {
	status: DialogStatus;
	sendData(value: ContactForm): void;
	resetStatus(): void;
}
