import { Button, Input, Textarea } from '@cue/atoms';
import styled from '@emotion/styled';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'ref'> & { label?: string };
type InputProps = Omit<JSX.IntrinsicElements['input'], 'ref'>;
type MultilineInputProps = Omit<JSX.IntrinsicElements['textarea'], 'ref'>;

export const ChatInput: React.FC<ChatInputProps> = ({
  onSend,
  buttonProps,
  inputProps,
  multiline = false,
  error,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm<ChatInputFormData>();

  return (
    <Container
      className="cue-chat-input"
      onSubmit={handleSubmit((data) => {
        onSend && onSend(data);
        reset();
      })}>
      <InputContainer className="cue-chat-input-container">
        {multiline ? (
          <Textarea
            {...register('message', { required: true })}
            name="message"
            {...(inputProps as MultilineInputProps)}
          />
        ) : (
          <Input
            {...register('message', { required: true })}
            name="message"
            {...(inputProps as InputProps)}
          />
        )}
      </InputContainer>
      <ButtonContainer className="cue-chat-input-send">
        <Button type="submit" {...buttonProps}>
          {buttonProps?.label ? buttonProps.label : t('chat.send')}
        </Button>
      </ButtonContainer>
      {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </Container>
  );
};

export interface ChatInputFormData {
  message: string;
}

export interface ChatInputProps {
  onSend?(data: ChatInputFormData): void;
  buttonProps?: ButtonProps;
  inputProps?: InputProps | MultilineInputProps;
  error?: Error;
  multiline?: boolean;
}

const ErrorMessage = styled.div({
  color: '#ff2640',
  textAlign: 'left',
  width: '100%',
});
const Container = styled.form`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div({
  marginRight: '1rem',
  flex: 1,
  '& > input': { width: '100%' },
});

const ButtonContainer = styled.div({});
