module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__esbuild@0.17.1_nksxsqeqigatcxwqvmdyecblce/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Siemens | SPS 2024 – Onsite & Digital","short_name":"SieSPS24","start_url":"/","background_color":"#ffffff","theme_color":"#000","display":"minimal-ui","icon":"src/themes/siemens/icon.png","icons":[{"src":"/facicon.ico","sizes":"16x16","type":"image/x-icon"},{"src":"/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/icons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"ICON","sizes":"48x48","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"24eb31abef52d13e6d83c31c8285bec5"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-matomo@0.16.3_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__esbuild@0.17.19__zbnipuvgyh63tuafl67sejtttm/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"matomoUrl":"//analytics.cuenect.de/","siteId":"99","dev":true,"requireConsent":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-adobe-launch@1.0.2_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__esbuild@0.1_bezkqksmp4hb3jkwrudkshom7u/node_modules/gatsby-plugin-adobe-launch/gatsby-browser.js'),
      options: {"plugins":[],"scriptUrl":"https://assets.adobedtm.com/5dfc7d97c6fb/3a40bdcd5280/launch-93978317ebba.min.js","includeInDevelopment":true,"defaultDataLayer":{"platform":"gatsby"},"dataLayerName":"SPS24","routeChangeEventName":"gatsbyRouteChange"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__esbuild@0.17.19_react-dom@18.3.1_react@18.3._qo3gjymdpkulxvwm7gzaizwh2e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
