import { PageContext } from "../../../../gatsby-node";
import { mustache } from "@cue/utility";
import { Helmet } from "react-helmet";

type PageMetaProps = {
	pageContext: PageContext;
};

export const PageMeta: React.FC<PageMetaProps> = (props) => {
	const { pageContext } = props;
	const { pageInfo, globalOptions, lang } = pageContext;

	const translatedGlobalOptions = Object.keys(globalOptions || {}).reduce(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(acc: Record<string, any>, key) => {
			const typedKey = key as keyof typeof globalOptions;

			if (Array.isArray(globalOptions[typedKey])) {
				// typescript not seeing runtime check
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				acc[key] =
					globalOptions[typedKey].find(({ language }) => language === lang)
						?.value || "";
			} else {
				acc[key] = globalOptions[typedKey];
			}
			return acc;
		},
		{},
	);

	const pageTitle =
		pageInfo &&
		mustache(translatedGlobalOptions?.seo_page_title || "", {
			...translatedGlobalOptions,
			...pageInfo,
		});
	console.log("translatedGlobalOptions", translatedGlobalOptions);
	const origin =
		typeof window !== "undefined"
			? document.location.origin
			: "https://schaltschrank.virtualevent.siemens.com";

	const pageDesc = Array.isArray(globalOptions?.seo_page_description)
		? globalOptions?.seo_page_description?.find(
				({ language }) => language === lang,
			)?.value || ""
		: "";
	const pageImage = `${origin}${
		Array.isArray(globalOptions?.seo_page_image)
			? globalOptions?.seo_page_image?.find(({ language }) => language === lang)
					?.value || ""
			: globalOptions?.seo_page_image
	}`;
	/*    title:
    globalOptions?.seo_page_title?.find(({ language }) => language === lang)?.value ||
    undefined,
  description:
    globalOptions?.seo_page_description?.find(({ language }) => language === lang)?.value ||
    undefined,
  image: globalOptions?.seo_page_image,
  image_width: 1280,
  image_height: 720, */

	return (
		<Helmet>
			{pageTitle ? <title>{pageTitle}</title> : null}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@Siemens" />
			<meta name="twitter:url" content={origin} />
			{pageTitle ? <meta property="og:title" content={pageTitle} /> : null}
			{pageTitle ? (
				<meta property="twitter:title" content={pageTitle || ""} />
			) : null}
			{pageDesc ? (
				<meta property="og:description" content={pageDesc || ""} />
			) : null}
			{pageDesc ? (
				<meta property="twitter:description" content={pageDesc || ""} />
			) : null}
			{pageImage ? <meta property="og:image" content={pageImage} /> : null}
			{pageImage ? <meta property="twitter:image" content={pageImage} /> : null}
		</Helmet>
	);
};
